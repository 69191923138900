import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Cards from "../components/knowledgeCentre/Cards";
import Layout from "../components/Layout";
import KnowledgeCentrePage from "../templates/KnowledgeCentrePage";

const seo = {
  title: "Blogs",
  description:
    "Read our blog series for a short introduction into the fundamentals of mechanics and mechanical testing. The articles cover everything from the basics of stresses and necking during tensile tests to new advanced testing methods such as PIP testing",
};

function BlogArticlesPage() {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/blogArticles/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              date
              image {
                childImageSharp {
                  gatsbyImageData(width: 390)
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <KnowledgeCentrePage seo={seo} linkPath="/blog-articles">
      <Cards
        title={"Blog Articles"}
        byline={
          "Read our blog series for a short introduction into the fundamentals of mechanics and mechanical testing."
        }
        data={data}
      />
    </KnowledgeCentrePage>
  );
}

export default BlogArticlesPage;
